import { Button } from "react-bootstrap";
import Typography from "../../../common/components/Typography";
import { MenuItems, MiniLeagues } from "../../../common/constants";
import { useNavigate } from "react-router-dom";
import { FeaturedMiniLeagueView } from "./FeaturedMiniLeague";
import "./style.scss";

export const MiniLeagueDashboard = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="d-flex justify-content-between mini-league-dashboard-wrapper">
        <Typography variant="pageTitle">{MenuItems.miniLeagues}</Typography>
        <Button onClick={() => navigate("/mini-leagues/new")}>
          {MiniLeagues.addMiniLeague}
        </Button>
      </div>
      <FeaturedMiniLeagueView />
    </>
  );
};
