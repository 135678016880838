import React, { useCallback, useEffect, useState } from "react";
import { defaultPageSize, EMPTY_ERROR } from "../../../../common/constants";
import { HttpStatus } from "../../../../utils/constants";
import { FeaturedMiniLeagueList } from "./FeaturedMiniLeagueList";
import { getMiniLeagueList } from "../service";
import { getPaginationQuery } from "../../../../utils/userUtil";
import { useLocation } from "react-router-dom";

export const FeaturedMiniLeagueView = () => {
  const location = useLocation();
  const { page, pageSize } = location.state || {};
  const [featuredMiniLeague, setFeaturedMiniLeague] = useState<any>({
    data: null,
    isLoading: false,
    error: EMPTY_ERROR,
  });
  const [featuredMiniLeaguePagesize, setFeaturedMiniLeaguePagesize] = useState(
    pageSize || defaultPageSize,
  );
  const [featuredMiniLeagueCurrentPage, setFeaturedMiniLeagueCurrentPage] =
    useState(page || 1);
  const getFeaturedMiniLeague = useCallback(async () => {
    setFeaturedMiniLeague((prev) => ({
      ...prev,
      isLoading: true,
      error: EMPTY_ERROR,
    }));

    const resData: any = await getMiniLeagueList({
      page: getPaginationQuery(
        featuredMiniLeagueCurrentPage,
        featuredMiniLeaguePagesize,
      ),
    });
    if (
      resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
      resData?.status === HttpStatus.RESOURCE_CREATED
    ) {
      setFeaturedMiniLeague((prev) => ({
        ...prev,
        isLoading: false,
        data: resData?.data?.data,
      }));
    } else {
      setFeaturedMiniLeague((prev) => ({
        ...prev,
        data: null,
        isLoading: false,
        error: { errorMsg: resData?.message, isError: true },
      }));
    }
  }, [
    featuredMiniLeagueCurrentPage,
    featuredMiniLeaguePagesize,
    setFeaturedMiniLeague,
  ]);

  useEffect(() => {
    getFeaturedMiniLeague();
  }, [getFeaturedMiniLeague]);
  return (
    <>
      <FeaturedMiniLeagueList
        featuredMiniLeague={featuredMiniLeague}
        featuredMiniLeaguePagesize={featuredMiniLeaguePagesize}
        setFeaturedMiniLeaguePagesize={setFeaturedMiniLeaguePagesize}
        featuredMiniLeagueCurrentPage={featuredMiniLeagueCurrentPage}
        setFeaturedMiniLeagueCurrentPage={setFeaturedMiniLeagueCurrentPage}
      />
    </>
  );
};
