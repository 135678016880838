import React from "react";
import TableWrapper from "../../../../../common/components/ReactTable/TableWrapper";
import ReactTable from "../../../../../common/components/ReactTable/ReactTable";
import { MiniLeagues } from "../../../../../common/constants";
import { useNavigate } from "react-router-dom";
import { EditPencil } from "../../../../../common/SVG";
import { Button } from "react-bootstrap";
import { TrueFalseIconViewer } from "../../../../../common/components/TrueFalseIconViewer";
import {
  getKickOffMatchFormat,
  getNextUnpublishedMatch,
} from "../../../miniLeagueUtil";
export const FeaturedMiniLeagueList = ({
  featuredMiniLeague,
  featuredMiniLeaguePagesize,
  setFeaturedMiniLeaguePagesize,
  featuredMiniLeagueCurrentPage,
  setFeaturedMiniLeagueCurrentPage,
}) => {
  const navigate = useNavigate();
  const columns = React.useMemo(
    () => [
      {
        accessorKey: "name",
        id: "name",
        header: () => <span>{MiniLeagues.featured.name}</span>,
        cell: (info) => info.getValue(),
        canSort: false,
      },
      {
        accessorKey: "uniqueCode",
        id: "uniqueCode",
        header: () => <span>{MiniLeagues.featured.leagueCode}</span>,
        cell: (info) => info.getValue(),
        canSort: false,
      },
      {
        accessorKey: "match",
        id: "kickOffMatch",
        header: () => <span>{MiniLeagues.featured.kickoffmatch}</span>,
        cell: (info) => (
          <span className="no-wrap">
            {getKickOffMatchFormat(info.getValue())}
          </span>
        ),
        canSort: false,
      },
      {
        id: "nextUnpublishedMatch",
        header: () => <span>{MiniLeagues.featured.nextMatchToPublish}</span>,
        cell: ({ row }) => (
          <span className="no-wrap">
            {getNextUnpublishedMatch(row?.original?.featuredMatches)}
          </span>
        ),
        canSort: false,
      },
      {
        accessorKey: "published",
        id: "published",
        header: () => <span>{MiniLeagues.featured.published}</span>,
        cell: (info) => <TrueFalseIconViewer value={info?.getValue()} />,
        canSort: false,
      },
      {
        accessorKey: "prizeDisbursed",
        id: "prizeDisbursed",
        header: () => <span>{MiniLeagues.featured.prizeDisbursed}</span>,
        cell: (info) => <TrueFalseIconViewer value={info?.getValue()} />,
        canSort: false,
      },
      {
        accessorKey: "totalTeams",
        id: "totalUsers",
        header: () => <span>{MiniLeagues.featured.totalUsers}</span>,
        cell: (info) => info.getValue(),
        canSort: false,
      },
      {
        accessorKey: "edit",
        id: "edit",
        header: () => <span>{MiniLeagues.featured.action}</span>,
        cell: ({ row }) => (
          <div className="align-center">
            {
              <Button
                size="sm"
                variant="link"
                onClick={() => {
                  navigate(`/mini-leagues/${row?.original?.id}`, {
                    state: {
                      page: featuredMiniLeagueCurrentPage,
                      pageSize: featuredMiniLeaguePagesize,
                    },
                  });
                }}
              >
                <EditPencil />
              </Button>
            }
          </div>
        ),
        canSort: false,
      },
    ],
    [navigate, featuredMiniLeagueCurrentPage, featuredMiniLeaguePagesize],
  );

  return (
    <>
      <TableWrapper tableClassName="mini-league-table">
        <ReactTable
          isLoading={featuredMiniLeague?.isLoading}
          data={featuredMiniLeague?.data?.miniLeagues || []}
          hasError={featuredMiniLeague?.error?.isError}
          errorMessage={featuredMiniLeague?.error?.errorMsg}
          columns={columns}
          pageChangeHandler={setFeaturedMiniLeagueCurrentPage}
          pageSizeHandler={setFeaturedMiniLeaguePagesize}
          totalRows={featuredMiniLeague?.data?.totalCount} //total records
          rowsPerPage={featuredMiniLeaguePagesize} //no of records per page
          currentPageNumber={featuredMiniLeagueCurrentPage}
        />
      </TableWrapper>
    </>
  );
};
