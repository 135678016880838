import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import NotFound from "./NotFound";
import { LoginView } from "./Login";
import Counter from "./Demofolder/components/Counter";
import Layout from "./Layout/Layout";
import MatchList from "./teamslist/component/MatchList";
import { Players } from "./Players/component/PlayerList";
import { PlayerInfo } from "./Players/component/PlayerInfo";
import { PointsTable } from "./FutyPoints/component/PointsTable";
import { ReferralsTable } from "./Referrals/component/ReferralsTable";
import { AddReferral } from "./Referrals/component/AddReferral";
import PushNotification from "./PushNotification/components/PushNotification";
import TeamsColorConfig from "./teamslist/component/TeamsColorConfig";
import ConfirmationModal from "./common/components/Modal/ConfirmationModel";
import { useDispatch, useSelector } from "react-redux";
import { resetSessionExpireData } from "./redux/sessionExpireSlice";
import {
  leaguesSeasonInitialState,
  setLeagueSeasonMetadata,
} from "./redux/leagueSeasonSlice";
import { useNavigate } from "react-router-dom";
import { Stats } from "./Stats/component/Stats";
import { LeaguesConfigure } from "./LeaguesConfigure/components";
import { RewardsView } from "./Rewards/components";
import { ManageUser } from "./ManageUser/components";
import { HttpStatus } from "./utils/constants";
import { fetchLeaguesData } from "./LeaguesConfigure/components/service";
import { getLeaguesOptionData } from "./LeaguesConfigure/components/leaguesUtils";
import { getCommonError } from "./common/message";
import { UserIdentityView } from "./UserIdentity/component";
import { MiniLeagueDashboard } from "./LeaguesConfigure/MiniLeagues/components";
import { UserIdentityDetailView } from "./UserIdentity/component/UserDetails/UserIdentityDetailView";
import { AddMiniLeague } from "./LeaguesConfigure/MiniLeagues/components/FeaturedMiniLeague/AddMiniLeague";

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const sessionData = useSelector((state: any) => state.session);
  const leaguesMetadata = useSelector((state: any) => state.leagues);
  const handleCloseConfirmation = () => {
    dispatch(resetSessionExpireData());
    localStorage.clear();
    navigate("/login");
  };
  const getLeaguesData = async () => {
    dispatch(
      setLeagueSeasonMetadata({
        ...leaguesSeasonInitialState,
        isLoading: true,
      }),
    );
    const resData: any = await fetchLeaguesData();
    if (
      resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
      resData?.status === HttpStatus.RESOURCE_CREATED
    ) {
      dispatch(
        setLeagueSeasonMetadata({
          ...leaguesSeasonInitialState,
          leaguesSeason: getLeaguesOptionData(resData?.data?.data),
        }),
      );
    } else {
      dispatch(
        setLeagueSeasonMetadata({
          isLoading: false,
          isError: true,
          errorMsg: getCommonError(resData?.message),
          leaguesSeason: [],
        }),
      );
    }
  };

  useEffect(() => {
    if (location?.pathname !== "/login") {
      const leaguesData = leaguesMetadata.leaguesSeason;
      if (location?.pathname === "/leagues" || !leaguesData?.length)
        getLeaguesData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.pathname]);

  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Stats />} />
          <Route path="/leagues" element={<LeaguesConfigure />} />
          <Route path="/mini-leagues" element={<MiniLeagueDashboard />} />
          <Route
            path="/mini-leagues/:miniLeagueId"
            element={<AddMiniLeague />}
          />
          <Route path="/matches" element={<MatchList />} />
          <Route path="/players" element={<Players />} />
          <Route path="/teams" element={<TeamsColorConfig />} />
          <Route path="/players/:playerId" element={<PlayerInfo />} />
          <Route path="/futy-points" element={<PointsTable />} />
          <Route path="/referrals" element={<ReferralsTable />} />
          <Route path="/referrals/:referralId" element={<AddReferral />} />
          <Route path="/push-notification" element={<PushNotification />} />
          <Route path="/manage-user" element={<ManageUser />} />
          <Route path="/rewards" element={<RewardsView />} />
          <Route path="/user-identity" element={<UserIdentityView />} />
          <Route
            path="/user-identity/:userIdentityId"
            element={<UserIdentityDetailView />}
          />
        </Route>
        <Route path="/login" element={<LoginView />} />
        <Route path="/counter-demo" element={<Counter />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <ConfirmationModal
        variant={sessionData?.variant}
        show={sessionData?.show}
        title={sessionData?.title}
        handleClick={() => {
          handleCloseConfirmation();
        }}
        handleClose={() => handleCloseConfirmation()}
        btnText="Ok"
      />
    </>
  );
}

export default App;
